<template>
  <div>

     <b-modal size="xl" hide-footer v-model="new_modal_reservation" class="w-100" title="NUEVA RESERVA">

      <b-tabs content-class="mt-3">
          <b-tab title="Reserva" active>
            <b-form @submit.prevent="Validation">
              <b-row>
                  <b-col sm="12" md="12">
                    <b-form-group>
                      <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                      <v-select @input="setSelectedClient" placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClient" :options="clients"></v-select>
                      <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Tipo de Habitación :">
                      <b-form-select v-model="reservation.id_type_room" @change="ListRoom" :options="type_room"></b-form-select>
                      <small v-if="errors.id_type_room" class="form-text text-danger" >Seleccione un tipo de habitación</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Habitación :">
                      <b-form-select v-model="reservation.id_room" @change="ViewRoom" :options="room"></b-form-select>
                      <small v-if="errors.id_room" class="form-text text-danger" >Seleccione una habitación</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Desde :">
                      <b-form-input class="text-center" @change="CalculateDays" :max="reservation.to" type="date" v-model="reservation.from" ></b-form-input>
                      <small v-if="errors.from" class="form-text text-danger" >Seleccione una fecha</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Hora :">
                      <b-form-input class="text-center" type="time" v-model="reservation.hour_from" ></b-form-input>
                      <small v-if="errors.hour_from" class="form-text text-danger" >Seleccione una hora</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Hasta :">
                      <b-form-input class="text-center" @change="CalculateDays" :min="reservation.from" type="date" v-model="reservation.to" ></b-form-input>
                      <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group label="Hora :">
                      <b-form-input class="text-center" type="time" v-model="reservation.hour_to" ></b-form-input>
                      <small v-if="errors.hour_to" class="form-text text-danger" >Seleccione una hora</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Noches :">
                      <b-form-input disabled type="number" class="text-center" v-model="reservation.nights" ></b-form-input>
                      <small v-if="errors.nights" class="form-text text-danger" >Seleccione las fechas</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Precio :">
                      <b-form-input  type="number" @change="CalculateDays"  step="any" class="text-right" v-model="reservation.price" ></b-form-input>
                      <small v-if="errors.price" class="form-text text-danger" >Ingrese un precio</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Total :">
                      <b-form-input disabled type="number"  step="any" class="text-right" v-model="reservation.total" ></b-form-input>
                      <small v-if="errors.total" class="form-text text-danger" >Ingrese el monto total</small>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Medio de Reserva :">
                      <b-form-select v-model="reservation.reserve_medium" :options="reserve_medium"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group>
                      <label class="control-label">Observación:</label>
                      <b-form-textarea v-model="reservation.observation"></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group>
                      <b-button class="form-control btn-primary" type="submit">REGISTRAR RESERVA</b-button>
                    </b-form-group>
                  </b-col>


              </b-row>
            </b-form>
          </b-tab>
          <b-tab title="Pago">

            <b-form @submit.prevent="AddReservationPayment">
              <b-row>
                

         

                  <b-col sm="6" md="3">
                    <b-form-group>
                      <label class="control-label">Forma de Pago: </label>
                      <b-form-select v-model="reservation_payment.payment_type" :options="payment_type"></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="3">
                    <b-form-group>
                      <label class="control-label">Referencia: </label>
                      <b-form-input v-model="reservation_payment.reference" type="text"></b-form-input>                    
                    </b-form-group>
                  </b-col>

                  <b-col sm="6" md="2">
                    <b-form-group>
                      <label class="control-label">Moneda: </label>
                      <b-form-select v-model="reservation_payment.coin" :options="coins"></b-form-select>
                      <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                    </b-form-group>
                  </b-col>


                  <b-col sm="12" md="2">
                    <b-form-group>
                      <label class="control-label">Total: </label>
                      <b-form-input v-model="reservation_payment.total" class="text-right" type="number" step="any"></b-form-input> 
                      <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto</small>                     
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="2">
                    <b-form-group>
                      <label class="control-label">.</label>
                      <b-button class="form-control btn-primary" type="submit">PAGAR</b-button>
                                      
                    </b-form-group>
                  </b-col>

             
              </b-row>
            </b-form>

            <b-col md="12">
                        <div class="table-responsive mt-3">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th width="5%" class="text-center">#</th>
                                <th width="35%" class="text-center">Medio de Pago</th>
                                <th width="30%" class="text-center">Referencia</th>
                                <th width="10%" class="text-center">Moneda</th>
                                <th width="10%" class="text-center">Total</th>
                                <th width="10%" class="text-center">Acciones</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, index) in reservation_payments" :key="index">
                              <tr>
                                  <td class="text-center">{{ index + 1 }}</td>
                                  <td class="text-center">{{ GetPaymentType(item.payment_type) }}</td>
                                  <td class="text-left">{{ item.reference }}</td>
                                  <td class="text-center">{{ item.coin }}</td>
                                  <td class="text-right">{{ item.total }}</td>
                                  <td class="text-center">
                                    <b-button type="button" @click="DeleteReservationPayment(index)" class="btn btn-sm btn-danger"><i class="fas fa-trash-alt"></i></b-button>
                                  </td>
                              </tr>
                                  
                            </tbody>
                          </table>
                        </div>
            </b-col>
          </b-tab>
      </b-tabs>
       
    </b-modal>


    <LoadingComponent :is-visible="isLoading"/>

  </div>
</template>
<style>

</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../../pages/Loading'
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";
import { parse } from '@babel/core';


export default {
  name: "UsuarioList",
  components:{

    vSelect,
    Loading,
    LoadingComponent,
  },
  data() {
    return {
      isLoading:false,
      module:'Reservation',
      role: 1,
      new_modal_reservation:false,
      selectInfo: null,
      reservation:{
        id_reservation:'',
        id_client:'',
        id_type_room:'',
        id_room:'',
        from:'',
        hour_from:'',
        to:'',
        hour_to:'',
        nights:0,
        price:0,
        total:0,
        reserve_medium:'',
        payment_type:'',
        reference:'',
        total_paid:0,
        coin:'PEN',
        observation:'',
        state:1,
        
      },
      reservation_payment: {
          id_reservation_payment:'',
          id_user:'',
          coin:'PEN',
          payment_type:'01-001',
          reference:'',
          observation:'',
          total:(0).toFixed(2),
          state:'1',
          number_to_letters : '',
      },
      reserve_medium:[
        {value:'',text:'-- Seleccione un medio ..'},
        {value:'Booking',text:'Booking'},
        {value:'Facebook',text:'Facebook'},
        {value:'Instagram',text:'Instagram'},
        {value:'WhatsApp',text:'WhatsApp'},
        {value:'Llamada',text:'Llamada'},
        {value:'Personal',text:'Personal'},
      ],
      payment_type:[
          {value:'',text:'-- Seleccione --'},
          {value:'01-001',text:'Contado - Efectivo'},
            {value:'01-002',text:'Contado - Visa'},
            {value:'01-003',text:'Contado - Mastercard'},
            {value:'01-004',text:'Contado - American Express'},
            {value:'01-005',text:'Contado - Transferencia de Fondos'},
            {value:'03-000',text:'Crédito'},
         
      ],
      coins:[
        {value: 'PEN', text:'PEN'},
        {value: 'USD', text:'USD'},
        {value: 'CLP', text:'CLP'},
      ],

      clients:[],
      client:{id:1,full_name: 'VENTA DEL DIA - 00000000'},
      
      type_room:[],
      room:[{value:'',text:'-- Seleccione --'}],

      reservation_payments:[],
      errors:{
        id_client:false,
        id_type_room:false,
        id_room:false,
        from:false,
        to:false,
        nights:false,
        price:false,
        total:false,
      },


    };

  },
  mounted() {
    
        EventBus.$on('ShowModalNewReservation', (selectInfo) => {
            this.new_modal_reservation = true;
            
            this.client = {id:1,full_name: 'VENTA DEL DIA - 00000000'};
            this.reservation.id_type_room = '';
            this.reservation.id_room = '';
            this.reservation.price = 0;
            this.reservation.total = 0;
            this.reservation.reserve_medium = '';
            this.reservation.payment_type = '';
            this.reservation.total_paid = 0;
            this.reservation.observation = '';
            this.reservation.nights = 1;

            this.reservation.hour_from = "12:00:00";
            this.reservation.hour_to = "12:00:00";

            

            this.reservation_payment.id_reservation_payment = '';
            this.reservation_payment.coin = 'PEN';
            this.reservation_payment.payment_type = '01-001';
            this.reservation_payment.reference = '';
            this.reservation_payment.observation = '';
            this.reservation_payment.total = (0).toFixed(2);
            this.reservation_payment.number_to_letters = '';

            this.reservation_payments = [];

   

            this.selectInfo = selectInfo;
            this.reservation.from = selectInfo.startStr;
            this.reservation.to = selectInfo.endStr;
            this.CalculateDays();

            
            



        });
        this.ListTypeRoom();
        
  },
  methods: {

      setSelectedClient(value) {
          if (value != null) {
            this.invoice_customer = value;
          }
      },
      SearchClient,
      ListTypeRoom,
      ListRoom,
      ViewRoom,
      CalculateDays,
      ShowModalClient,
  
      Validation,
      AddReservation,
      AddReservationPayment,
      DeleteReservationPayment,
      GetPaymentType,

      
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function SearchClient(search) {
  let me = this;
  let url = this.url_base + "search-clients/" + search;
  if (search !== "") {
    axios({
      method: "GET",
      url: url,
    }).then(function (response) {
          me.clients = response.data;
    })
  }
}

function GetPaymentType(payment_type) {
  let data = '';
  switch (payment_type) {
    case '01-001': data = 'Contado - Efectivo'; break;
    case '01-002': data = 'Contado - Visa'; break;
    case '01-003': data = 'Contado - Mastercard'; break;
    case '01-004': data = 'Contado - American Express'; break;
    case '01-005': data = 'Contado - Transferencia de Fondos'; break;
    case '01-006': data = 'Contado - Deposito en Cuenta'; break;
    default:
      break;
  }
  return data;
         
}
function ListTypeRoom() {
  let me = this;
  let url = this.url_base + "active-type-room";
  axios({
    method: "GET",
    url: url,
  }).then(function (response) {
      me.type_room = [{value:'',text:'-- Seleccione --'}];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.type_room.push({value:element.id_type_room,text:element.name})
      }
      
  })
  
}
function ListRoom() {
  if (this.reservation.id_type_room.length == 0) {
    this.room = [{value:'',text:'-- Seleccione una habitación'}];
    this.reservation.id_room = '';
    this.reservation.price = (0).toFixed();
    this.CalculateDays();
    return false;
  }
  let me = this;
  let url = this.url_base + "active-room-by-type-room/"+this.reservation.id_type_room;
  axios({
    method: "GET",
    url: url,
  }).then(function (response) {
       me.room = [{value:'',text:'-- Seleccione una habitación'}];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.room.push({value:element.id_room,text:element.room_number})
      }
  })
}
function ViewRoom() {
   if (this.reservation.id_room.length == 0) {
    this.reservation.price = (0).toFixed(2);
    return false;
  }
  let me = this;
  let url = this.url_base + "room/view/"+this.reservation.id_room;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role },
  }).then(function (response) {
      if (response.data.status == 200) {
        me.reservation.price = parseFloat(response.data.result.price).toFixed(2);
        me.CalculateDays();
      }
  })
}
function CalculateDays() {
    let date1 = moment(this.reservation.from);
    let date2 = moment(this.reservation.to);
    this.reservation.nights = date2.diff(date1, 'days');

    let total = parseFloat(this.reservation.nights) * parseFloat(this.reservation.price);

    this.reservation.price = parseFloat(this.reservation.price).toFixed(2);
    this.reservation.total = total.toFixed(2);
    

}
function ShowModalClient() {
  EventBus.$emit('ModalClient');
}
function AddReservation(_this) {
  let me = _this;
  me.reservation.id_user = me.user.id_user;
  me.reservation.id_client = me.client.id;
  me.reservation.reservation_payments = me.reservation_payments;

  let url = me.url_base + "reservation/add";
  let data = me.reservation;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.new_modal_reservation = false;
        let info = {
          id: response.data.result.id_reservation,
          title: 'HAB. ' + response.data.result.name_type_room + ' | '+ response.data.result.room_number+ ' | '+ response.data.result.name+ ' | '+ response.data.result.document_number,
          start: response.data.result.from,
          end: response.data.result.to,
          backgroundColor: '#000000',
        };

        EventBus.$emit('AddEventCalendar',info);
        Swal.fire({ icon: 'success', text: 'Se ha registrado exitosamente la reserva',  timer: 3000,  })
        
 
        let url_pdf = me.url_base + "reservation-pdf/"+response.data.result.id_reservation;
        
        window.open(url_pdf,'_blank');

      }else{
        Swal.fire({ icon: 'error', text: response.data.message,  timer: 3000,  })
      }

      me.isLoading = false;
    })
    .catch((error) => {
      me.isLoading = false;
      // Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
  
}
function Validation() {


  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_type_room = this.reservation.id_type_room.length == 0 ? true : false;
  this.errors.id_room = this.reservation.id_room.length == 0 ? true : false;
  this.errors.from = this.reservation.from.length == 0 ? true : false;
  this.errors.to = this.reservation.to.length == 0 ? true : false;
  this.errors.nights = this.reservation.nights.length == 0 ? true : false;
  this.errors.price = this.reservation.price.length == 0 ? true : false;
  this.errors.total = this.reservation.total.length == 0 ? true : false;


  if (this.errors.id_client) { return false;}
  if (this.errors.id_type_room) { return false;}
  if (this.errors.id_room) { return false;}
  if (this.errors.from) { return false;}
  if (this.errors.to) { return false;}
  if (this.errors.nights) { return false;}
  if (this.errors.price) { return false;}
  if (this.errors.total) { return false;}


  let me = this;
  Swal.fire({
    title: 'Esta seguro de realizar a reserva?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      AddReservation(me);
    }
  })
}

function AddReservationPayment() {
  

  if (this.reservation_payment.payment_type.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione una forma de pago',  timer: 2000,  })
    return false;
  }
  if (parseFloat(this.reservation_payment.total) <= 0 || this.reservation_payment.total.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese un monto',  timer: 2000,  })
    return false;
  }
  let total = 0;
  for (var i = 0; i < this.reservation_payments.length; i++) {
      total += parseFloat(this.reservation_payments[i].total);
  }
  
  total += parseFloat(this.reservation_payment.total);
  console.log(total)
  if (parseFloat(this.reservation.total) < total) {
    Swal.fire({ icon: 'warning', text: 'El monto de la pago es superior a la reserva',  timer: 2000,  })
    return false;
  }

  let payment = {
    payment_type : this.reservation_payment.payment_type,
    reference : this.reservation_payment.reference,
    coin : this.reservation_payment.coin,
    observation : this.reservation_payment.observation,
    total : this.reservation_payment.total,
  };

  this.reservation_payments.push(payment);
}

function DeleteReservationPayment(index) {

  for (var i = 0; i < this.reservation_payments.length; i++) {
    if (i == index) {
      this.reservation_payments.splice(i, 1);
      break;
    }
  }
}
// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
