<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reserva </strong>
          </CCardHeader>
          <CCardBody>
         

              <FullCalendar ref="fullCalendar" :options='calendarOptions' ></FullCalendar>
      

     


          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


     <!-- <b-modal size="md" hide-footer v-model="modal_reservation" class="w-100" title="CLIENTE">
      <b-row>
        <b-col md="12">
           asdasdada
        </b-col>
      </b-row>
    </b-modal> -->


    <ModalNewReservation/>
    <ModalViewReservation/>
    <ModalClient/>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'

import ModalClient from './../components/ModalClient'
import ModalNewReservation from './components/ModalNewReservation'
import ModalViewReservation from './components/ModalViewReservation'

export default {
  name: "UsuarioList",
  components: {
    FullCalendar,
    ModalClient,
    ModalNewReservation,
    ModalViewReservation,
  },
  data() {
    return {
      module:'Reservation',
      role: 1,
      search: "",
      data_table: [],
      modal_reservation:false,

      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        // events: [],
        locale: 'es',
        editable: false,
        selectable: true,
        selectMirror: true,
        // dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        handleDateClick:this.handleDateClick,
        
        
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: []


    };

  },
  mounted() {
    this.dataevents();
    EventBus.$on('AddEventCalendar', (info) => {
      
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.addEvent({
          id: info.id,
          title: info.title,
          start: info.start,
          end: info.end,
          backgroundColor: info.backgroundColor
        })

    });
    EventBus.$on('EditEventCalendar', (info) => {

        let calendarApi = this.$refs.fullCalendar.getApi(); 
        let event = calendarApi.getEventById(info.id)
        event.remove();

        calendarApi.addEvent({
          id: info.id,
          title: info.title,
          start: info.start,
          end: info.end,
          backgroundColor: info.backgroundColor
        })

    });
    EventBus.$on('DeleteEventCalendar', (id_reservation) => {

        let calendarApi = this.$refs.fullCalendar.getApi(); 
        let event = calendarApi.getEventById(id_reservation)
        event.remove();

    });

    
    this.ListReservations();
  },
  methods: {
     dataevents(){
    

    
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
      console.log("handleWeekendsToggle")
    },

    handleDateSelect(selectInfo) {
      EventBus.$emit('ShowModalNewReservation',selectInfo);
    },

    handleEventClick(clickInfo) {
        EventBus.$emit('ShowModalViewReservation',clickInfo.event.id);
    },

    handleEvents(events) {
      // console.log(events);
      // console.log("handleEvents")
    },
    handleDateClick(arg) {
      alert('date click! ' + arg.dateStr)
    },




    ListReservations,





    ListCategory,
    EditCategory,
    ViewCategory,
    ConfirmDeleteCategory,
    DeleteCategory,
    Permission,

    // handleDateClick: function(arg) {
    //   this.modal_reservation = true;
    //   alert('date click! ' + arg.dateStr)
    // },
    // InfoReservation: function(info) {
    //   console.log(info)
    //   // this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
    //   // this.modal_reservation = true;
    // }
    
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function ListReservations() {

  let url =  this.url_base + "reservation/list-reservation";
  let me = this;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 1,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
      
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          let info = {
            id: element.id_reservation,
            title: element.number + ' | '+ element.name_type_room + ' | '+ element.room_number+ ' | '+ element.name+ ' | '+ element.document_number,
            start: element.from,
            end: element.to,
            backgroundColor: '#000000',
          };
  
          EventBus.$emit('AddEventCalendar',info);
 
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}




//listar usuario
function ListCategory() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url =
    this.url_base + "category/list/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 1,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditCategory(id_category) {
  this.$router.push({
    name: "CategoryEdit",
    params: { id_category: je.encrypt(id_category) },
  });
}

// Ver Usuario
function ViewCategory(id_category) {
  this.$router.push({
    name: "CategoryView",
    params: { id_category: je.encrypt(id_category) },
  });
}

// Confirmar eliminar
function ConfirmDeleteCategory(id_category) {
  Swal.fire({
    title: "Esta seguro de eliminar el registro?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCategory(id_category);
    }
  });
}

// eliminar usuario
function DeleteCategory(id_category) {
  let me = this;
  let url = this.url_base + "category/delete/" + id_category;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        //eliminado del objeto
        for (var i = 0; i < me.data_table.length; i++) {
          if (me.data_table[i].id_category == id_category) {
            me.data_table.splice(i, 1);
            break;
          }
        }
        Swal.fire("Eliminado!", "El registro ha sido eliminado", "success");
      } else {
        Swal.fire(
          "A Ocurrido un error",
          "El registro no ha sido eliminado",
          "error"
        );
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
